import classNames from 'classnames';
import React from 'react';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { S7Image } from '~/components/Images/S7Image';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { LazyPicture } from '~/util/Components/LazyPicture';

export const CardMediaImage = (props) => {
	const {
		title,
		imageLink = null,
		links = null,
		sizes = null,
		src,
		srcSet,
		srcSm,
		smallSrcSet,
		mediumSrcSet,
		fallbackSrc,
		triggerLoad = false,
		useLazyPicture = false,
		height = 0,
		heightSm = 0,
		priority = false,
		useS7Image = false,
		width = 0,
		widthSm = 0,
	} = props;

	let linkEventName = title;

	if (links && links.length > 0) {
		linkEventName = links[0].linkCopy;
	}

	let CardImage;

	if (useS7Image) {
		CardImage = <>
			<div className={classNames({ 'md:tw-block tw-hidden': srcSm })}>
				<S7Image
					alt=""
					height={height}
					priority={priority}
					sizes={sizes}
					src={src}
					width={width}
				/>
			</div>
			{srcSm && <div className="md:tw-hidden">
				<S7Image
					alt=""
					height={heightSm}
					priority={priority}
					src={srcSm}
					width={widthSm}
				/>
			</div>}
		</>;
	} else {
		CardImage = useLazyPicture
			? <LazyPicture
				smallSrcSet={smallSrcSet}
				mediumSrcSet={mediumSrcSet}
				fallbackSrc={fallbackSrc}
				triggerLoad={triggerLoad}
			/>
			: <LazyImage
				src={src}
				srcSet={srcSet}
				sizes={sizes}
				triggerLoad={triggerLoad}
				alt=""
				placeholderQuality="20"
			/>;
	}

	return (
		<>
			<ConditionalWrapper
				condition={imageLink}
				wrapper={children => <a data-tr-link-event-name={linkEventName} href={imageLink}>{children}</a>}
			>
				{CardImage}
			</ConditionalWrapper>
		</>
	);
};
