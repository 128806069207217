import React from 'react';

export const CardCopyParagraphs = (props) => {
	const {
		copy: copyList,
		cardCopyClass,
	} = props;

	return (
		<>
			{
				copyList.map((copy, index) => {
					const { text = copy } = copy;
					return (
						<p key={`copy-${index}`} className={cardCopyClass}>{text}</p>
					);
				})
			}
		</>
	);
};
