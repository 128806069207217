import React, { createContext, useContext } from 'react';
import { enableStaticRendering } from 'mobx-react';

import { BreadcrumbModelFactory } from '~/layout/Models/Breadcrumb.model';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { DeliveryCenterModelFactory } from '~/locations/Models/DeliveryCenter.model';
import { storeData, deliveryCenterData } from '~/locations/Data/storeData';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { curalateStoresDataSourceId, curalateStoresFanreelId, isOnServer } from '~/global/global.constants';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';
import { CuralateStoreFactory } from '~/util/curalate/Stores/Curalate.store';
import { storeLocationsData } from '~/locations/Data/storeLocationsData';
import { StoreModelFactory } from '~/locations/Models/Store.model';
import { EventFactory } from '~/locations/Models/Event.model';
import { EventRSVPFactory } from '~/locations/Models/EventRSVP.model';

const storeContext = createContext();

enableStaticRendering(isOnServer);

const transformCategoryGalleryToMediaSetData = ((items, startingImageIndex = 0) => {
	return items.map((item, index) => {
		const {
			image: {
				src: fileName = '',
				altText = '',
			},
			caption = '',
		} = item;

		return {
			fileName,
			altText,
			caption,
			selected: index === startingImageIndex,
			type: 'IMAGE_WITH_CAPTION',
		};
	});
});

export const StoreContextProvider = (props) => {
	const {
		magicModal,
		cookies,
		featureTogglesModel,
	} = useGlobalContext();

	const {
		children,
		pageProps: {
			model: pageModel = {},
			model: {
				query: {
					store = '',
					ii: startingImage = 0,
				},
				galleryItems = [],
				events = [],
			} = {},
		} = {},
	} = props;

	const storeJson = storeData[store];

	if (storeJson) {
		const deliveryCenterJson = deliveryCenterData[storeJson.location.deliveryCenter];
		const model = StoreModelFactory.create(pageModel);

		model.breadcrumb = BreadcrumbModelFactory.create(storeLocationsData, model.breadcrumbID);

		model.events = events.map((event) => {
			const eventModel = EventFactory.create(event);
			if (event.rsvp) {
				eventModel.rsvp = EventRSVPFactory.create(event.rsvp);
			}
			return eventModel;
		});

		model.deliveryCenter = DeliveryCenterModelFactory.create(deliveryCenterJson);
		model.mediaSet = createMediaSetModel({ mediaSetData: transformCategoryGalleryToMediaSetData(galleryItems, startingImage - 1) });

		// eslint-disable-next-line prefer-destructuring
		const selectedMediaIndex = model.mediaSet.selectedMediaIndex;
		const mediaSetStore = MediaSetStoreFactory.create({ mediaSetModel: model.mediaSet });

		if (model.mediaSet.mediaModels.length > selectedMediaIndex) {
			mediaSetStore.selectMediaModel(model.mediaSet.mediaModels[selectedMediaIndex]);
		}

		const filter = `label:${storeJson.location?.curalateLabel}`;
		const curalateStore = CuralateStoreFactory.create(cookies, magicModal, featureTogglesModel, {
			dataSourceId: curalateStoresDataSourceId,
			experienceId: 'product-page-experience',
			fanreelId: curalateStoresFanreelId,
			filter,
			limit: 50,
			itemsPerPage: 5,
		});

		const context = {
			curalateStore,
			model,
			mediaSetStore,
			startingImageIndex: model.mediaSet.selectedMediaIndex,
			validatedStoreQuery: store,
		};

		return <storeContext.Provider value={context}>{children}</storeContext.Provider>;
	}

	return <storeContext.Provider value={{ error: 'JSON data not found' }}>{children}</storeContext.Provider>;
};

export const useStoreContext = () => useContext(storeContext);
