'use client';

import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import { CuralateItemImage } from '~/util/curalate/Components/CuralateItemImage';
import { isEngage } from '~/global/global.constants';

import styles from '~/util/curalate/Components/curalate.module.scss';

export const CuralateItem = observer(({
	deferImageLoad,
	item,
	item: {
		focused,
		media,
		source: {
			user: {
				displayName = null,
				username = null,
			} = {},
		} = {},
	},
	srcSetSizeKey,
	srcSizeKey,
	srcDimensions = {},
	clickHandler,
}) => {
	const itemRef = useRef(null);

	const itemClickHandler = () => {
		clickHandler(item, itemRef.current);
	};

	const trLinkEventName = username || displayName;

	return (
		<button
			aria-pressed={focused}
			className={styles['curalate-item-button']}
			data-qa="curalate-item-button"
			data-tr-link-event-name={trLinkEventName}
			data-tr-link-event-track={!isEngage}
			onClick={itemClickHandler}
			ref={itemRef}
		>
			<CuralateItemImage
				deferImageLoad={deferImageLoad}
				media={media}
				srcSetSizeKey={srcSetSizeKey}
				srcSizeKey={srcSizeKey}
				srcDimensions={srcDimensions}
			/>
		</button>
	);
});
