import React from 'react';

import { CardCopyParagraphs } from '~/components/Cards/Common/Components/CardCopyParagraphs';

export const CardCopy = (props) => {
	const {
		copy,
		cardCopyClass,
	} = props;

	const copyArray = Array.isArray(copy);

	return (
		<>
			{copyArray ? <CardCopyParagraphs copy={copy} cardCopyClass={cardCopyClass} /> : <p className={cardCopyClass}>{copy}</p>}
		</>
	);
};
