import React from 'react';

import { CardMedia } from '~/components/Cards/CardMedia/Components/CardMedia';
import { s7ImagePath } from '~/global/global.constants';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';

export const VirtualTour = () => {
	const {
		model: {
			virtualTourLink
		} = {},
	} = useStoreContext();

	if (!virtualTourLink) {
		return false;
	}

	return (
		<CardMedia
			containerClass="tw-flex tw-flex-col tw-pb-8 md:tw-pt-12 tw-pt-4 md:tw-flex-row md:tw-pb-6 md:tw-space-x-0 md:tw-px-2"
			title="Look Inside"
			titleHeaderTag="h2"
			titleClass="Heading Heading--3"
			contentClass="tw-pb-5 md:tw-w-1/2 md:tw-pr-16"
			copy="Get a peek at our store from the comfort of home. Find great ideas for modern living."
			links={[
				{
					linkClass: 'HollowButton HollowButton--gray HollowButton--transparent',
					linkCopy: 'Take a Virtual Tour',
					linkTarget: '_blank',
					linkURL: virtualTourLink,
					linkExternal: true
				}
			]}

			imageClass="md:tw-w-1/2"
			src={`${s7ImagePath}/free_design_services_virtual_tour_0821?wid=1166`}
			srcSet={`${s7ImagePath}/free_design_services_virtual_tour_0821?wid=583, ${s7ImagePath}/free_design_services_virtual_tour_0821?wid=1166 2x`}
			textFirst={true}
			textFirstMd={true} />
	);
};
