import React, { useEffect, useState } from 'react';

import styles from '#/locations/aboutUs.module.scss';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';

export const AboutUs = () => {
	const {
		model: {
			aboutUsComponents = null,
		} = {},
	} = useStoreContext();

	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		setHasLoaded(true);
	}, []);

	return (
		hasLoaded && aboutUsComponents &&
			<div className={`${styles.AboutUs}`}>
				<div className="md:tw-w-10/12">
					<h2 className={`Heading Heading--3 ${styles.AboutUsHeadline}`}>About the Store & Neighborhood</h2>
					<div className={'Body Body--1'}>{aboutUsComponents}</div>
				</div>
			</div>

	);
};
