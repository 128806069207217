import parse, { domToReact } from 'html-react-parser';
import { makeObservable, computed } from 'mobx';
import DOMPurify from 'dompurify';

import { TextLink } from '~/components/Buttons/Components/TextLink';

import { s7ImagePath } from '~/global/global.constants';

export class Address {
	title;

	subTitle;

	street;

	city;

	state;

	zipCode;

	mapLink;

	mapImage;

	mapImageRetina;

	directions;

	parkingText;

	publicTransit;

	constructor() {
		makeObservable(this, {
			parkingComponents: computed,
		});
	}

	get parkingComponents() {
		if (typeof window === 'undefined') {
			return null;
		}
		const cleanHtml = DOMPurify.sanitize(this.parkingText);
		return parse(cleanHtml, {
			replace: (domNode) => {
				if (domNode.name === 'a') {
					const { href, ...rest } = domNode.attribs;
					const children = domNode.children ? domToReact(domNode.children) : null;
					return (
						<TextLink href={href} linkExternal className="tw-text-xl tw-font-light tw-inline tw-break-word" {...rest}>
							{children}
						</TextLink>
					);
				}
			},
		});
	}
}

export const AddressFactory = ({
	create: (addressJson = {}) => {
		const {
			title = '',
			subTitle = '',
			streetAddress = '',
			city = '',
			state = '',
			zipCode = '',
			mapLink = '',
			mapImageName = '',
			directions = '',
			parkingText = '',
			publicTransitText = '',
		} = addressJson;

		const model = new Address();

		Object.assign(model, {
			title,
			subTitle,
			street: streetAddress,
			city,
			state,
			zipCode,
			mapLink,
			mapImage: `${s7ImagePath}/${mapImageName}?size=584,502&scl=1`,
			mapImageRetina: `${s7ImagePath}/${mapImageName}?size=1168,1184&scl=1 2x`,
			parkingText,
			directions,
			publicTransit: publicTransitText.publicTransitOption,
		});

		return model;
	},
});
