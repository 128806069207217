import React, { useState, useEffect } from 'react';

import { useStoreContext } from '~/locations//Contexts/StorePages.context';
import { Address } from '~/locations/Components/Address';
import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { LinkBlock } from '~/components/Buttons/Components/LinkBlock';
import styles from '#/locations/address.module.scss';

export const LocationDetails = () => {
	const {
		model: {
			address: {
				directions,
				mapLink,
				mapImage,
				mapImageRetina,
				parkingComponents,
				publicTransit,
			} = {},
			address,
		} = {},
	} = useStoreContext();

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	return (
		<div
			className={`${styles['location-details']} tw-flex tw-flex-col md:tw-items-center md:tw-flex-row md:tw-space-x-5`}
		>
			<div className={`${styles['location-directions']} md:tw-w-1/2`}>
				<Address address={address} />
				{directions &&
					<div className={styles['location-driving']}>
						<h2 className={styles['location-headline']}>Driving Directions</h2>
						<p className="tw-body-1" dangerouslySetInnerHTML={{ __html: directions }}></p>
					</div>
				}
				{parkingComponents &&
					<div className={styles['location-parking']}>
						<h2 className={styles['location-headline']}>Parking</h2>
						<div className="tw-body-1">{parkingComponents}</div>
					</div>
				}
				{publicTransit &&
					<div className={styles['location-transit']}>
						<h2 className={styles['location-headline']}>Public Transit</h2>
						<ul className="tw-body-1">
							{publicTransit.map((publicTransitOption, index) => {
								return (<li key={`publicTransitOption-${index}`}>{publicTransitOption}</li>);
							})}
						</ul>
					</div>
				}
			</div>
			<div className={`${styles['location-map']} md:tw-w-1/2`}>
				<ConditionalWrapper
					condition={mapLink}
					wrapper={children => <LinkBlock target="_blank" rel="noreferrer" linkExternal href={mapLink} data-tr-link-event-name="get directions">{children}</LinkBlock>}>
					<img alt="get directions" src={mapImage} srcSet={mapImageRetina} />
				</ConditionalWrapper>
			</div>
		</div>
	);
};
