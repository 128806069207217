import React from 'react';

import styles from './Events.module.scss';

import { s7ImagePath } from '~/global/global.constants';
import { Event } from '~/locations/Components/Event';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { LazyPicture } from '~/util/Components/LazyPicture';

export const Events = () => {
	const {
		model: {
			events = [],
			query: {
				store = '',
			}
		} = {},
	} = useStoreContext();

	return (
		<div className="md:tw-pt-12 tw-pt-4 tw-mb-16">
			<div className={styles['image-grid']}>
				<div className="tw-justify-self-end">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/Stores_events_1_sm_v2?wid=211, ${s7ImagePath}/Stores_events_1_sm_v2?wid=422 2x`}
						mediumSrcSet={`${s7ImagePath}/Stores_events_1?wid=292, ${s7ImagePath}/Stores_events_1?wid=584 2x`}
						fallbackSrc={`${s7ImagePath}/Stores_events_1?wid=584&fallback`}
					/>
				</div>
				<div className="tw-hidden md:tw-block">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/1x1`}
						mediumSrcSet={`${s7ImagePath}/Stores_events_2?wid=584, ${s7ImagePath}/Stores_events_2?wid=1164 2x`}
						fallbackSrc={`${s7ImagePath}/Stores_events_2?wid=584`}
					/>
				</div>
				<div className="">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/Stores_events_3_sm_v2?wid=211, ${s7ImagePath}/Stores_events_3_sm_v2?wid=422 2x`}
						mediumSrcSet={`${s7ImagePath}/Stores_events_3?wid=292, ${s7ImagePath}/Stores_events_3?wid=584 2x`}
						fallbackSrc={`${s7ImagePath}/Stores_events_3?wid=422`}
					/>
				</div>
			</div>

			<h3 className="Heading Heading--3 tw-text-gray">Store Events</h3>
			<p className="Body Body--1 tw-mb-8">Join us for store-hosted events! They’re a great way to get involved with the local community, enjoy sips and snacks, and meet our talented team of design experts.</p>

			<div className="tw-pb-10 tw-border-b-gray md:tw-grid md:tw-grid-cols-2 md:tw-gap-x-4 md:tw-gap-y-8">
				{
					events.length === 0 && (
						<em className="Body Body--1 tw-text-gray">There are currently no events scheduled.</em>
					)
				}
				{
					events.length > 0 && events.map((event = {}) => {
						return (
							<Event key={`event-${event.id}`} event={event} store={store} />
						);
					})
				}
			</div>

			<div className="tw-flex tw-justify-center tw-mt-12">
				<div className="md:tw-text-center md:tw-w-8/12">
					<h4 className="Heading Heading--3 tw-text-gray tw-mb-1">Want To Hear About Our Next Event?</h4>
					<p className="Body Body--1 tw-text-gray tw-mb-5 md:tw-mb-6">Get on the invite list to be one of the first to know!</p>

					<a
						className="HollowButton HollowButton--gray"
						href="/opt-in"
					>
						Sign Up for Email Invites
					</a>
				</div>
			</div>

		</div>
	);
};
