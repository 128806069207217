import React from 'react';

import styles from '#/locations/favorites.module.scss';
import { FavoriteProduct } from '~/locations/Components/favorites/FavoriteProduct';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';

export const Favorites = () => {
	const {
		model: {
			storeName,
			titleOverride,
			favorites = [],
		} = {},
	} = useStoreContext();

	if (!favorites) {
		return false;
	}

	return (
		<div className={styles.FavoriteProducts}>
			<h2 className={`${styles.FavoriteProductHeadline} Heading Heading--3`}>
				{!titleOverride ? storeName : titleOverride} Favorites</h2>
			<ul className="md:tw-space-x-5">
				{favorites.map((product, index) => {
					return <FavoriteProduct key={index} {...product} />;
				})}
			</ul>
		</div>
	);
};
