import React from 'react';

import styles from './Event.module.scss';

import { EventRSVP } from '~/locations/Components/EventRSVP';

function createMarkup(markup) {
	return { __html: markup };
}

export const Event = (props = {}) => {
	const {
		event,
		event: {
			calendarDateDisplay,
			description,
			eventDateDisplay = '',
			time,
			title,
		} = {},
		store,
	} = props;

	return (
		<div className={styles['event']} data-qa="event">
			<div className={styles['calendar-date']} data-qa="event-date">
				<div
					className={`${styles['calendar-date-text']} Body Body--2`}
					dangerouslySetInnerHTML={createMarkup(calendarDateDisplay)}
				/>
			</div>
			<div>
				<h4
					className="Heading Heading--4 tw-text-gray"
					dangerouslySetInnerHTML={createMarkup(title)}
				/>
				{time && eventDateDisplay && <div className="Body Body--2">{eventDateDisplay}, {time}</div>}
				{time && !eventDateDisplay && <div className="Body Body--2">{time}</div>}
				{!time && <div className="Body Body--2">{eventDateDisplay}</div>}

				<div
					className="tw-my-3 Body Body--2"
					dangerouslySetInnerHTML={createMarkup(description)}
				/>

				<EventRSVP event={event} store={store} />
			</div>
		</div>
	);
};
