import React from 'react';

import { CardMedia } from '~/components/Cards/CardMedia/Components/CardMedia';
import { s7ImagePath } from '~/global/global.constants';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';

export const FreeDesignServices = () => {
	const {
		model: {
			jrni,
			storeNumber
		} = {},
	} = useStoreContext();

	const copyList = [
		{
			listKey: 1,
			listCopy: 'Furniture selection'
		},
		{
			listKey: 2,
			listCopy: 'Floor planning'
		},
		{
			listKey: 3,
			listCopy: 'Color, paint and material selection'
		},
		{
			listKey: 4,
			listCopy: 'Home decor selection'
		},
		{
			listKey: 5,
			listCopy: 'Business design services'
		},
	];

	const apptURL = `https://roomandboard.bookingbug.com/book?storeId=${storeNumber}-${jrni}&category=1`;

	return (
		<CardMedia
			containerClass="tw-pb-10 md:tw-pt-12 tw-pt-4 md:tw-px-2 md:tw-pb-4"
			title="Free Design Services"
			titleHeaderTag="h2"
			titleClass="Heading Heading--3"
			contentClass="md:tw-mx-5 md:tw-max-w-lg"
			copy="Get personalized ideas and advice from our non-commissioned Design Associates. We can help with:"
			copyList={copyList}
			copyListClass="Body Body--1"
			links={[
				{
					linkClass: 'HollowButton HollowButton--gray HollowButton--transparent',
					linkCopy: 'Make an Appointment',
					linkURL: apptURL,
					linkExternal: true,
				}
			]}
			imageClass="md:tw-w-6/12"
			src={`${s7ImagePath}/free_design_services_0821?wid=1166`}
			srcSet={`${s7ImagePath}/free_design_services_0821?wid=583, ${s7ImagePath}/free_design_services_0821?wid=1166 2x`} />
	);
};
