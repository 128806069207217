import React from 'react';

import styles from '#/locations/address.module.scss';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const Address = ({
	isStore = true,
	address: {
		title,
		subTitle,
		street,
		city,
		state,
		zipCode,
		mapLink,
	} = {},
}) => {
	const headline = isStore ? 'Address' : 'Address & Contact Us';
	const mapLinkText = isStore ? 'Get directions' : 'View map and get directions';

	return (
		<div className={styles['location-address']}>
			<h2 className="Heading Heading--5 tw-font-normal tw-text-gray md:tw-mb-2">{headline}</h2>
			<p className="Body Body--1">
				{isStore &&
					title && <span>{title} <br /></span>
				}
				{subTitle && <span>{subTitle} <br /></span> }
				{street && street}<br />
				{city && city}{state && `, ${state}`}{zipCode && ` ${zipCode}`}
				{
					mapLink &&
					<span>
						<br />
						<TextLink
							className="Body Body--1"
							href={mapLink}
							target="_blank"
							rel="noreferrer"
							linkExternal
						>{mapLinkText}</TextLink>
					</span>
				}
			</p>
		</div>
	);
};
