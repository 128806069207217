import React from 'react';

import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { s7ImagePath } from '~/global/global.constants';

export const DCSchema = () => {
	const {
		model: {
			imageName,
			deliveryCenter: {
				address: {
					street,
					city,
					state,
					zipCode,
				} = {},
				contact: {
					phone,
				} = {},
				openingHours,
				title,
			}
		} = {},
	} = useStoreContext();

	const schema = {
		'@context': 'http://schema.org',
		'@type': 'LocalBusiness',
		'name': title,
		'openingHours': openingHours,
		'telephone': phone.replace(/\./g, ''),
		'image': `${s7ImagePath}/${imageName}?size=1200,500`,
		'address': {
			'@type': 'PostalAddress',
			'streetAddress': street,
			'addressLocality': city,
			'addressRegion': state,
			'postalCode': zipCode,
		}
	};

	return <script
		type="application/ld+json"
		id="delivery-center-schema-data"
		key="delivery-center-schema-data"
		dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};
