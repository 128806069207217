import { s7ImagePath } from '~/global/global.constants';

export class Favorites {
	link

	name

	imagePath

	imagePathRetina
}

export const FavoritesModelFactory = ({
	create: (favorite = {}) => {
		const {
			s7Image = '',
			link = '',
			name = '',
		} = favorite;

		const model = new Favorites();

		Object.assign(model, {
			imagePath: `${s7ImagePath}/${s7Image}&wid=280`,
			imagePathRetina: `${s7ImagePath}/${s7Image}&wid=560`,
			link,
			name,
		});

		return model;
	},
});
