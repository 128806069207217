import React from 'react';

import { s7ContentPath } from '~/global/global.constants';
import styles from '#/locations/contact.module.scss';

export const Contact = (props) => {
	const {
		email,
		phone,
		telPhone = phone.replace(/\./g, ''),
		toEmailName,
		isStore = true,
	} = props;

	return (
		<div className={`${styles.Contact} Body Body--1`}>
			<div className={styles.ContactEmail}>
				<a href={`mailto:${email}`}>
					<img src={`${s7ContentPath}/icon_email`} alt="" width="30" />
					<span>Email {toEmailName}</span>
				</a>
			</div>
			<div className={styles.ContactPhone}>
				<a
					href={`tel:+1${telPhone}`}
				>
					<img src={`${s7ContentPath}/icon_phone`} alt="" width="30" />{phone}
				</a>
				{isStore &&
					<span className={`${styles.ContactCustCare} Body Body--2`}>Customer Care</span>
				}
			</div>
		</div>
	);
};
