import React from 'react';
import { observer } from 'mobx-react';

import { isEngage } from '~/global/global.constants';

import styles from '~/util/curalate/Components/curalate.module.scss';

export const CuralateItemPlaceholder = observer((props) => {
	const {
		curalateStore: {
			openCuralateUploadModal,
		} = {},
	} = props;

	return (
		<button
			className={`${styles['curalate-item-button']} ${styles['curalate-item-placeholder-button']}`}
			data-tr-link-event-track={!isEngage}
			data-qa="curalate-item-button"
			onClick={openCuralateUploadModal}
		>
			<span aria-hidden className="curalate-item-placeholder-plus">+</span>
			<span className="tw-sr-only">Add a photo</span>
		</button>
	);
});
