import React from 'react';

export const CardTitle = (props) => {
	const {
		titleClass,
		title,
	} = props;

	return <props.tag className={`${titleClass}`}>{title}</props.tag>;
};
