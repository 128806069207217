import React from 'react';

import styles from '#/locations/hours.module.scss';

export const Hours = (props) => {
	const {
		storeName,
		hours = [],
		specialMessaging: {
			title,
			html,
		},
		hoursHtml,
	} = props;

	return (
		<div className={styles.Hours}>
			<h2 className="Heading Heading--5 tw-font-normal tw-text-gray md:tw-mb-2">Hours</h2>
			{ hoursHtml &&
				<div className="Body Body--1 tw-mb-4 md:tw-mb-8" dangerouslySetInnerHTML={{ __html: hoursHtml }}>
				</div>
			}
			{ hours && !hoursHtml &&
				<ul className="Body Body--1 tw-mb-4 md:tw-mb-8">
					{
						hours.map(({ days, times }) => {
							return (
								<li key={`${storeName}-${days}-${times}`}><span className={`${styles.HoursDay}`}>{days}</span> {times}</li>
							);
						})
					}
				</ul>
			}
			{html &&
				<div className={styles.HoursHoliday}>
					{title &&
						<h2 className={`${styles.HoursHolidayHeading} Heading Heading--5`} dangerouslySetInnerHTML={{ __html: title }}></h2>
					}
					<div className={`${styles.HoursHolidayText} Body Body--1`} dangerouslySetInnerHTML={{ __html: html }} />
				</div>
			}
		</div>
	);
};
