class EventRSVP {
	friendlyUrl

	capactiy

	totalAttending

	imageUrl

	line1

	line2

	line3

	calendarStartTime

	calendarEndTime

	links

	get addRSVPEntryLink() {
		return this.links.addRsvpEntry?.href;
	}

	get rsvpLink() {
		return this.links.rsvp?.href;
	}
}

export const EventRSVPFactory = ({
	create: (rsvpJson = {}) => {
		const {
			friendlyUrl,
			capactiy,
			totalAttending,
			imageUrl,
			line1,
			line2,
			line3,
			calendarStartTime,
			calendarEndTime,
			_links = {},
		} = rsvpJson;

		const model = new EventRSVP();

		Object.assign(model, {
			friendlyUrl,
			capactiy,
			totalAttending,
			imageUrl,
			line1,
			line2,
			line3,
			calendarStartTime,
			calendarEndTime,
			links: _links,
		});

		return model;
	},
});
