import { GallerySliderPanelImage } from '~/components/Gallery/GallerySliderPanelImage';
import { MOVE_TYPE } from '~/components/Slider/Slider.constants';
import { s7ImagePath } from '~/global/global.constants';

import type { GallerySliderPanelImageData } from '~/components/Gallery/Gallery.types';

export const galleryProps = {
	defaultSliderHeight: 610,
	showCaption: true,
	showControlsArrows: true,
	showCounter: true,
	showDots: true,
	showFullscreenSm: true,
	showSliderCounter: true,
	sliderDotsProps: {
		panelsPerView: 7,
	},
	sliderProps: {
		circular: true,
		moveType: MOVE_TYPE.STRICT,
		resizeDebounce: 100,
	},
};

const imageGalleryData = [
	{
		image: {
			src: 'maeve_062083_24e',
			width: 3000,
			height: 1813,
			altText: 'living room with maeve sofa, lind storage ottoman, soren lounge chair.'
		},
		url: 'catalog/timeless-design-ideas/maeve-sofa',
		urlFriendlyStrName: 'maeve-sofa'
	},
	{
		image: {
			src: 'copenhagen_856163_21e',
			width: 2440,
			height: 3000,
			altText: 'Green paint creates a soothing, nature-inspired backdrop for your furniture and decor. (Room & Board Paint by Hirshfield’s in Monogram shown here.)'
		},
		url: 'catalog/timeless-design-ideas/anton-bed',
		urlFriendlyStrName: 'anton-bed'
	},
	{
		image: {
			src: 'anton_009215_24e',
			width: 4931,
			height: 3000,
			altText: 'Bedroom with Anton queen bed in walnut and coles ten-drawer dresser in walnut with Nera rug in stone.'
		},
		url: 'catalog/timeless-design-ideas/anton-bed',
		urlFriendlyStrName: 'anton-bed'
	},
	{
		image: {
			src: 'sterling_034902_23e',
			width: 3000,
			height: 1763,
			altText: 'Sterling Sofa with Chilton Bench'
		},
		caption: 'Combine a sleek sofa with a patterned rug and a live-edge table to create a more traditional feel.',
		url: 'catalog/timeless-design-ideas/sterling-sofa-with-chilton-bench',
		urlFriendlyStrName: 'sterling-sofa-with-chilton-bench'
	},
	{
		image: {
			src: 'architecture_433936_23e',
			width: 2150,
			height: 3000,
			altText: 'bedroom with architecture queen bed in natural steel with sumner fabric headboard.'
		},
		url: 'catalog/timeless-design-ideas/architecture-bed-classic',
		urlFriendlyStrName: 'architecture-bed-classic'
	},
	{
		image: {
			src: 'berkeley_383392_18e',
			width: 2353,
			height: 3000,
			altText: 'Detail of Berkeley bedroom collection.'
		},
		caption: 'Storage drawers take the Berkeley bed from being beautiful to being beautiful and functional. We love the continuity between the nightstand and bed drawers for a seamless, cohesive look.',
		url: 'catalog/timeless-design-ideas/berkeley-queen-bed-in-walnut',
		urlFriendlyStrName: 'berkeley-queen-bed-in-walnut'
	},
	{
		image: {
			src: 'linden_366391_22e1',
			width: 3000,
			height: 2335,
			altText: 'dining room with linden table, four thatcher chairs and peyton chair.'
		},
		caption: 'Our take on traditional dining sets: One wood tone mixed and matched with a variety of table, chair and storage pieces.',
		url: 'catalog/timeless-design-ideas/linden-dining-table',
		urlFriendlyStrName: 'linden-dining-table'
	},
	{
		image: {
			src: 'metro_077925_23e',
			width: 3000,
			height: 1974,
			altText: 'living room with angled metro sofa in tatum fabric, bram leather chairs, orlin coffee table, veda rug.'
		},
		caption: 'This living room has a warm, classic look, thanks to a vintage-inspired rug and pieces that showcase timeless design.',
		url: 'catalog/timeless-design-ideas/metro-left-arm-angled-sofa',
		urlFriendlyStrName: 'metro-left-arm-angled-sofa'
	},
	{
		image: {
			src: 'avery_124467_23e',
			width: 4632,
			height: 3000,
			altText: 'bedroom with avery queen storage bed in view fabric, amherst 6-drawer dresser, liam end table.'
		},
		url: 'catalog/timeless-design-ideas/classic-bedroom-with-avery-bed',
		urlFriendlyStrName: 'classic-bedroom-with-avery-bed'
	},
	{
		image: {
			src: 'york_400581_21e',
			width: 3000,
			height: 1934,
			altText: 'room setting including york sofa, otis swivel chair, ericson lounge chairs, chilton coffee table.'
		},
		caption: 'An interesting mix of wood pieces and a comfortable sofa offer a warm welcome to family and guests.',
		url: 'catalog/timeless-design-ideas/york-sofa-with-classic-end-table',
		urlFriendlyStrName: 'york-sofa-with-classic-end-table'
	},
	{
		image: {
			src: 'chapman_158957_23e',
			width: 4498,
			height: 3000,
			altText: 'Bedroom with chapman queen bed, emerson nightstand, veda rug; bathroom with linear vanity.'
		},
		url: 'catalog/timeless-design-ideas/classic-bedroom-with-chapman',
		urlFriendlyStrName: 'classic-bedroom-with-chapman'
	}
];

export const imageGalleryPanels: GallerySliderPanelImageData[] = imageGalleryData.map(({
	caption,
	image: {
		altText,
		height,
		src,
		width,
	},
}) => {
	const aspectRatio = width / height;

	return {
		SliderPanelComponent: GallerySliderPanelImage,
		sliderPanelComponentProps: {
			aspectRatio,
			caption,
			imageProps: {
				alt: altText,
				src: `${s7ImagePath}/${src}`,
			},
			width,
		}
	};
});
