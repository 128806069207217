import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { MediaCaption } from '~/media-set/Components/MediaCaption';
import { MediaNavNext, MediaNavPrev } from '~/media-set/Components/MediaNav';
import { MediaThumbnail } from '~/media-set/Components/MediaThumbnail';
import { noop } from '~/util/noop';
import styles from '#/media-set/media-set.module.scss';
import { MediaSetVariants } from '~/media-set/Helpers/MediaSetVariants';
import { Media } from '~/media-set/Components/Media';
import { updateSlidesAttributes } from '~/media-set/Utils/Media.utils';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import sharedSlickStyles from '~/components/content-slick-slider/content-slick-slider-shared.module.scss';

export const MediaSetWithSliderNav = observer((props = {}) => {
	const {
		infinite = true,
		initialSlide = 0,
		mainHeight = 732,
		mainWidth = 993,
		mediaComponentsOverride = {},
		mediaSetModel = {},
		mediaSetModel: {
			hasCaption = false,
			isMediaSetOverrideActive = false,
			isSingleMediaSet = false,
			mediaModels = [],
			mediaModelsCount = 0,
			selectedMediaModel = 0,
			selectedMediaIndex = 0,
		} = {},
		mediaSetOverride,
		mediaSetOverrideBeforeChange = noop,
		mediaSetAfterGoToCallback = noop,
		mediaSetStore = {},
		navSlidesToShow = 10,
		showArrowsForMedium = false,
		showArrowsForSmall = false,
		showDimensions = false,
		showDriftZoom = false,
		showDotsForMedium = false,
		showFullScreenButton = false,
		showThumbnailsForSmall = false,
		slidesToShow = 1,
		speed = 300,
		thumbnailHeight = 100,
		thumbnailWidth = 136,
		touchMove = true,
		trLinkEventCompName,
		trLinkEventCompType,
		trLinkEventUseCompNameH1 = null,
	} = props;

	const {
		linkEventStore,
	} = useGlobalContext();

	const mediaSetVariant = MediaSetVariants.SLIDING_THUMBNAILS;

	const didInitializeRef = useRef(false);

	const mediaSetRef = useRef();

	const containerRef = useRef();

	const navContainerRef = useRef();

	const navSetRef = useRef();

	const navSettings = {
		afterChange: () => {
			updateSlidesAttributes(navContainerRef);
		},
		arrows: !isSingleMediaSet,
		className: styles['media-set-sliding-navigation'],
		focusOnSelect: false,
		infinite: false,
		initialSlide,
		nextArrow: (
			<MediaNavNext
				hideArrows={isMediaSetOverrideActive}
				scrollerHeight={thumbnailHeight}
				showArrowsForMedium={showArrowsForMedium}
				showArrowsForSmall={showArrowsForSmall}
			/>
		),
		prevArrow: (
			<MediaNavPrev
				hideArrows={isMediaSetOverrideActive}
				scrollerHeight={thumbnailHeight}
				showArrowsForMedium={showArrowsForMedium}
				showArrowsForSmall={showArrowsForSmall}
			/>
		),
		// setting slidesToShow to 0 will force the thumbnails to render for the single mediaSet scenario
		slidesToShow: isSingleMediaSet ? 0 : navSlidesToShow,
		slidesToScroll: 1,
		speed,
		touchMove: isSingleMediaSet ? false : touchMove,
		variableWidth: true,
	};

	const settings = {
		appendDots: () => {
			// when slidesToShow is set to 0 for single mediaSet, the library was returning 2 dots.
			// so we need to trim off the last dot when in that scenario

			return (
				<div ref={navContainerRef}>
					{
						hasCaption && (
							<div className="tw-hidden md:tw-block tw-hidden-for-print">
								<MediaCaption mediaModel={selectedMediaModel} variant={MediaSetVariants.SLIDING_THUMBNAILS} />
							</div>
						)
					}
					<Slider
						{...navSettings}
						className={`${navSettings.className} ${sharedSlickStyles['content-slick-slider-shared']}`}
						ref={navSetRef}
					>
						{
							mediaModels.map((mediaModel, index) => {
								return (
									<MediaThumbnail
										key={`nav-thumbnail-${index}`}
										hideSelected={isMediaSetOverrideActive}
										mediaComponentsOverride={mediaComponentsOverride}
										mediaModel={mediaModel}
										mediaSetModel={mediaSetModel}
										onClick={() => {
											runInAction(() => {
												if (mediaModelsCount > mediaModel.index) {
													mediaSetStore.selectMediaModel(mediaModel);
													if (window.eventDataLayer && linkEventStore) {
														const linkEventTrackingData = {
															trLinkEventName: `${showDotsForMedium ? 'dot' : 'thumbnail'} navigation`,
															trLinkEventCompPosition: `${mediaModel.index + 1}:${mediaModelsCount}`,
															trLinkEventType: LinkEventTypes.SITE_ACTION,
															trLinkEventCompName,
															trLinkEventUseCompNameH1, // if true this will override trLinkEventCompName above
															trLinkEventCompType,
														};

														// Only send the traking event for the selections made in the open "dropdown"
														linkEventStore.trackLinkEvent(linkEventTrackingData);
													}
												}

												mediaSetOverrideBeforeChange();
											});
										}}
										showDotsForMedium={showDotsForMedium}
										showThumbnailsForSmall={showThumbnailsForSmall}
										thumbnailHeight={thumbnailHeight}
										thumbnailIndex={index}
										thumbnailWidth={thumbnailWidth}
										variant={MediaSetVariants.SLIDING_THUMBNAILS}
									/>
								);
							})
						}
					</Slider>
				</div>
			);
		},
		arrows: !isSingleMediaSet,
		afterChange: () => {
			updateSlidesAttributes(containerRef);
		},
		beforeChange: (prevIndex, nextIndex) => {
			// let react-slick finish animating before triggering a re-render, or it will jank noticeably
			setTimeout(() => {
				runInAction(() => {
					if (mediaModelsCount > nextIndex) {
						mediaSetStore.selectMediaModel(mediaModels[nextIndex]);
					}

					mediaSetOverrideBeforeChange();
				});
			}, 150);
		},
		dots: true,
		infinite: isSingleMediaSet ? false : infinite,
		initialSlide,
		onInit: () => {
			setTimeout(() => {
				updateSlidesAttributes(containerRef);
			}, 150);
		},
		nextArrow: (
			<MediaNavNext
				hideArrows={isMediaSetOverrideActive}
				scrollerHeight={mainHeight}
				showArrowsForMedium={showArrowsForMedium}
				showArrowsForSmall={showArrowsForSmall}
				variant={mediaSetVariant}
			/>
		),
		prevArrow: (
			<MediaNavPrev
				hideArrows={isMediaSetOverrideActive}
				scrollerHeight={mainHeight}
				showArrowsForMedium={showArrowsForMedium}
				showArrowsForSmall={showArrowsForSmall}
				variant={mediaSetVariant}
			/>
		),
		// setting slidesToShow to 0 will force the thumbnails to render for the single mediaSet scenario
		slidesToShow: isSingleMediaSet ? 0 : slidesToShow,
		speed,
		touchMove: isSingleMediaSet ? false : touchMove,
	};

	// keep the slick instance in sync with programmatic selectedMediaIndex changes after intial mount
	useEffect(() => {
		if (didInitializeRef.current) {
			// allow mobx-react observer to finish its re-render before calling slickGoTo
			setTimeout(() => {
				mediaSetRef.current.slickGoTo(selectedMediaIndex);
				navSetRef.current.slickGoTo(selectedMediaIndex);
				mediaSetAfterGoToCallback();
			}, 200);
		} else {
			didInitializeRef.current = true;
		}
	}, [selectedMediaIndex]);

	return (
		<div
			className={
				classNames(styles['media-set'], {
					'media-set-has-caption': hasCaption,
					[styles['media-set-override']]: isMediaSetOverrideActive,
				})
			}
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-use-comp-name-h1={trLinkEventUseCompNameH1} // if true this will override the comp name set above
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-qa="media-set"
			ref={containerRef}
		>
			<p className="tw-sr-only">
				This section of the page contains a carousel that visually displays various linked images one at a time. For screen reader users, these images appear in a list below. Selecting the links changes the main slide visually.
			</p>
			{
				mediaSetOverride && (
					<div
						className={
							classNames({
								'tw-block': isMediaSetOverrideActive,
								'tw-hidden': !isMediaSetOverrideActive,
							})
						}
					>
						{mediaSetOverride}
					</div>
				)
			}
			<Slider
				{...settings}
				className={`${navSettings.className} ${sharedSlickStyles['content-slick-slider-shared']}`}
				ref={mediaSetRef}
			>
				{
					mediaModels.map((mediaModel, index) => {
						return (
							<div
								aria-label={`Media ${index + 1} of ${mediaModelsCount}`}
								key={`media-main-${index}`}
								role="group"
							>
								<Media
									imageHeight={mainHeight}
									imagePreset="$prodzoom0$"
									imageWidth={mainWidth}
									mediaComponentsOverride={mediaComponentsOverride}
									mediaModel={mediaModel}
									mediaSetModel={mediaSetModel}
									mediaSetStore={mediaSetStore}
									showDimensions={showDimensions}
									showDriftZoom={showDriftZoom}
									showFullScreenButton={showFullScreenButton}
									showMediaPlayer={true}
								/>
							</div>
						);
					})
				}
			</Slider>
			{
				hasCaption && (
					<div className="md:tw-hidden tw-hidden-for-print">
						<MediaCaption mediaModel={selectedMediaModel} />
					</div>
				)
			}
		</div>
	);
});
