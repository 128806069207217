import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Contact } from '~/locations/Components/Contact';
import { Hours } from '~/locations/Components/Hours';
import { s7ImagePath } from '~/global/global.constants';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import styles from '#/locations/storeDetails.module.scss';
import { useStoreContext } from '~/locations/Contexts/StorePages.context';

export const StoreDetails = () => {
	const {
		model: {
			storeName,
			titleOverride,
			storeSuffix,
			imageName,
			isNewStore = false,
			hours = [],
			contact: {
				email,
				phone
			} = {},
			key,
			storeHoursHtml,
			storeEmergencyMessaging: {
				html,
			},
			storeSpecialMessaging,
			dcKey,
			deliveryCenter: {
				title,
			}
		} = {},
	} = useStoreContext();

	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		setHasLoaded(true);
	}, []);

	const classes = cn(styles.StoreDetails, {
		'tw-pb-0': isNewStore,
	});

	return (
		<div className={classes}>
			<LazyImage
				src={`${s7ImagePath}/${imageName}?size=1200,500&scl=1`}
				srcSet={`
					${s7ImagePath}/${imageName}?size=420,175&scl=1 420w,
					${s7ImagePath}/${imageName}?size=840,350&scl=1 840w,
					${s7ImagePath}/${imageName}?size=1200,500&scl=1 1200w,
					${s7ImagePath}/${imageName}?size=2400,1000&scl=1 2400w`}
				sizes="(max-width: 640px) 420px, (max-width: 840px) 840px, (max-width: 1200px) 1200px, (max-width: 2400px) 2400px"
				triggerLoad={true}
				alt=""
				placeholderQuality="20"
			/>
			<div className={`${styles.StoreDetailsContent}`} data-qa="store-details-content">
				<div className="tw-mb-8">
					<h1 className={`${styles.StoreDetailsHeading} Heading Heading--1`}>
						{storeName}{storeSuffix && storeSuffix}
					</h1>
					{hasLoaded && html &&
						<p className={`${styles.StoreDetailsEmergencyText} Body Body--1`} dangerouslySetInnerHTML={{ __html: html }}></p>
					}
				</div>
				{!isNewStore &&
					<div className={`${styles.StoreDetailsInfo} `}>
						<div className={`${styles.StoreDetailsHours} tw-mb-6 md:tw-mb-0`}>
							{ hasLoaded &&
								<Hours hours={hours} hoursHtml={storeHoursHtml} specialMessaging={storeSpecialMessaging} />
							}
						</div>
						{key !== 'outlet' &&
							<div className={`${styles.StoreDetailsContact} Body Body--1`}>
								<h2 className={`${styles.StoreDetailsSubhead} Heading Heading--5`}>Contact Us</h2>
								<Contact
									email={email}
									phone={phone}
									toEmailName={`${!titleOverride ? storeName : titleOverride} store`}
								/>
								{dcKey &&
									<p><a href={`#dc-${dcKey}`}>{title}</a></p>
								}
							</div>
						}
					</div>
				}
			</div>
		</div>
	);
};
