import React from 'react';

export const CardCopyList = (props) => {
	const {
		copyList,
		copyListClass = '',
	} = props;

	return (
		<ul className={`${copyListClass}`}>
			{
				copyList.map(({ listKey, listCopy }) => {
					return (
						<li key={listKey}>{listCopy}</li>
					);
				})
			}
		</ul>
	);
};
