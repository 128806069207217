import React from 'react';
import classNames from 'classnames';

import styles from '../CardMedia.module.scss';

import { ButtonShopRoom } from '~/components/Buttons/ButtonShopRoom/Components/ButtonShopRoom';
import { CardMediaImage } from '~/components/Cards/CardMedia/Components/CardMediaImage';
import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { CardCopy } from '~/components/Cards/Common/Components/CardCopy';
import { CardCopyList } from '~/components/Cards/Common/Components/CardCopyList';
import { CardLinks } from '~/components/Cards/Common/Components/CardLinks';
import { CardTitle } from '~/components/Cards/Common/Components/CardTitle';

export const CardMedia = (props) => {
	const {
		alignTop = false,
		borderTop = null,
		borderTopSmall = null,
		containerClass,
		contentClass,
		imageClass,
		links = null,
		cardLink = null,
		cardLinkClass,
		title = null,
		textFirst = false, // text first on mobile
		textFirstMd = false, // imgRight
		imgMid = false, // Positions on mobile
		imgSTR = false,
		posAbsRight = true,
		shopRoomLink = null,
		titleClass = 'Heading Heading--2',
		titleHeaderTag = 'h2',
		copyClass = 'Body Body--1 tw-text-gray',
		linksStacked = false,
		linksStackedSmOnly = false,
		copyList = null,
		copyListClass = '',
	} = props;

	// for tracking we need a link event name, this can be the text of the first link if there are any or the headline
	let linkEventName = title;
	if (links && links.length > 0) {
		linkEventName = links[0].linkCopy;
	}

	let imgTop;

	if (!imgMid && !textFirst) {
		imgTop = true;
	} else {
		imgTop = false;
	}

	const imageClasses = classNames(
		imageClass,
		styles.CardMediaImage
	);

	const containerClasses = classNames(
		styles.CardMedia,
		{ 'tw-items-center': !alignTop },
		{ [styles['CardMedia--borderTop__sm']]: borderTopSmall },
		{ [styles['CardMedia--borderTop']]: borderTop },
		containerClass,
	);

	const imageClassesTop = classNames(
		imageClasses,
		textFirstMd ? 'md:tw-hidden' : 'md:tw-block',
		imgTop ? 'tw-block' : 'tw-hidden',
		imgSTR && 'tw-relative'
	);

	const imageClassesMid = classNames(
		imageClasses,
		imgMid ? 'tw-block' : 'tw-hidden',
		'md:tw-hidden',
		imgSTR && 'tw-relative',
	);

	const imageClassesBtm = classNames(
		imageClasses,
		textFirstMd ? 'md:tw-block' : 'md:tw-hidden',
		textFirst ? 'tw-block' : 'tw-hidden',
		imgSTR && 'tw-relative',
	);

	return (
		<div
			aria-label="article"
			className={containerClasses}
			data-tr-link-event-comp-type="card media"
			role="group"
		>
			<ConditionalWrapper
				condition={cardLink}
				wrapper={children => <a data-tr-link-event-name={linkEventName} href={cardLink} className={cardLinkClass}>{children}</a>}>
				{(!textFirstMd || !textFirst) &&
					<div className={imageClassesTop}>
						{imgSTR &&
							<ButtonShopRoom shopLink={shopRoomLink} posAbsRight={posAbsRight} />
						}
						<CardMediaImage {...props} />
					</div>
				}
				<div className={contentClass} data-tr-link-event-comp-name={title}>
					{ title && <CardTitle titleClass={`${styles.CardTitle} ${titleClass}`} title={title} tag={titleHeaderTag} /> }
					{imgMid &&
						<div className={imageClassesMid}>
							{imgSTR &&
								<ButtonShopRoom shopLink={shopRoomLink} posAbsRight={posAbsRight} />
							}
							<CardMediaImage {...props} />
						</div>
					}
					<CardCopy cardCopyClass={copyClass} {...props} />
					{copyList && <CardCopyList {...props} copyListClass={`${styles.CardMediaList} ${copyListClass}`} />}
					{links && <CardLinks linksClass={styles.CardLinks} links={links} linksStacked={linksStacked} linksStackedSmOnly={linksStackedSmOnly} />}
				</div>
				{(textFirstMd || textFirst) &&
					<div className={imageClassesBtm}>
						{imgSTR &&
							<ButtonShopRoom shopLink={shopRoomLink} posAbsRight={posAbsRight} />
						}
						<CardMediaImage {...props} />
					</div>
				}
			</ConditionalWrapper>
		</div>
	);
};
