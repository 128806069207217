import React from 'react';

import { PrimaryLayout } from '~/layout/Components/PrimaryLayout';
import { StoreContextProvider } from '~/locations/Contexts/StorePages.context';
import { StorePagesStoreFactory } from '~/locations/Stores/StorePages.store';
import { StorePagesModelFactory } from '~/locations/Models/StorePages.model';
import { StandardStore } from '~/locations/Components/StandardStore';
import { withPagePromise } from '~/global/app-config/withPagePromise';

function StorePage(pageProps) {
	return (
		<StoreContextProvider
			pageProps={pageProps}
		>
			<PrimaryLayout>
				<StandardStore />
			</PrimaryLayout>
		</StoreContextProvider>
	);
}

export const getServerSideProps = withPagePromise(async (pageProps, ctx) => {
	const { query, res, } = ctx;
	const { cookies } = pageProps;
	const model = StorePagesModelFactory.create(query);
	const store = StorePagesStoreFactory.create(model);

	try {
		const promise = await store.getStorePagesPromise(cookies);

		if (promise && promise.data) {
			pageProps.model = JSON.parse(JSON.stringify(model));

			//TODO: remove else during GALLERY_REDESIGN toggle cleanup
			if (promise.data.gallery) {
				pageProps.model.galleryItems = promise.data.gallery;
			} else if (promise.data.categoryGalleryItems) {
				pageProps.model.galleryItems = promise.data.categoryGalleryItems;
			}
		} else {
			res.statusCode = 404;
		}
	} catch (error) {
		console.error(error);
		pageProps.promiseError = error;
	}
	return {
		props: pageProps,
	};
});

export default StorePage;
