export class Contact {
	phone

	email
}

export const ContactFactory = ({
	create: (contactJson = {}) => {
		const {
			phone = '',
			email = '',
		} = contactJson;

		const model = new Contact();

		Object.assign(model, {
			phone,
			email,
		});

		return model;
	},
});
