import React from 'react';

import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { s7ImagePath } from '~/global/global.constants';

export const StoreSchema = () => {
	const {
		model: {
			address: {
				title,
				street,
				city,
				state,
				zipCode,
			} = {},
			contact: {
				phone = '',
			} = {},
			metaDescription,
			imageName = '',
			openingHours = '',
		} = {},
	} = useStoreContext();

	const schema = {
		'@context': 'http://schema.org',
		'@type': 'FurnitureStore',
		'name': title,
		'description': metaDescription,
		'openingHours': openingHours,
		'telephone': phone.replace(/\./g, ''),
		'image': `${s7ImagePath}/${imageName}?size=1200,500`,
		'address': {
			'@type': 'PostalAddress',
			'streetAddress': street,
			'addressLocality': city,
			'addressRegion': state,
			'postalCode': zipCode,
		}
	};
	return <script
		type="application/ld+json"
		id="store-schema-data"
		key="store-schema-data"
		dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};
