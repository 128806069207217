import React from 'react';

import { CardMedia } from '~/components/Cards/CardMedia/Components/CardMedia';
import { s7ImagePath } from '~/global/global.constants';

export const ExploreCareers = () => {
	return (
		<CardMedia
			containerClass="tw-flex tw-flex-col tw-pb-8 md:tw-flex-row md:tw-pb-6 md:tw-space-x-0 md:tw-pb-4 md:tw-px-2 md:tw-pt-12 tw-pt-6"
			title="Career Opportunities"
			titleHeaderTag="h2"
			titleClass="Heading Heading--3"
			contentClass="tw-pb-5 md:tw-w-1/2 md:tw-pr-10"
			copy="Find open positions and learn what it’s like to work at Room & Board."
			links={[
				{
					linkClass: 'HollowButton HollowButton--gray HollowButton--transparent',
					linkCopy: 'Explore Careers',
					linkURL: '/careers/',
				}
			]}
			imageClass="md:tw-w-1/2"
			useS7Image={true}
			src={`${s7ImagePath}/careers_promo_0824`}
			width="584"
			height="426"
			textFirst={true}
			textFirstMd={true} />
	);
};
