import React from 'react';

import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { Curalate } from '~/util/curalate/Components/Curalate';

export const CuralateLocation = () => {
	const {
		curalateStore = {},
		model: {
			hashTag,
		} = {},
	} = useStoreContext();

	return (
		<div>
			<Curalate disableItemPlaceholders={false} curalateStore={curalateStore} heading={`#${hashTag}`} headerLevel="h2" subheading="Check out photos of in-store inspiration and everyday happenings." className="tw-mb-0" />
		</div>
	);
};
