import React from 'react';

import styles from '#/locations/favorites.module.scss';

export const FavoriteProduct = ({
	link,
	name,
	imagePath,
	imagePathRetina,
}) => {
	return (
		<li>
			<a href={link}>
				<div className={styles.FavoriteProductsImg}>
					<img src={imagePath}
						srcSet={imagePathRetina}
						alt="" />
				</div>
				<span className="Heading Heading--5">{name}</span>
			</a>
		</li>
	);
};
