import React from 'react';
import cn from 'classnames';

import { CardLink } from '~/components/Cards/Common/Components/CardLink';

export const CardLinks = (props) => {
	const {
		links,
		linksClass,
		linksStacked,
		linksStackedSmOnly,
	} = props;

	return (
		<div className={cn(linksClass, 'ButtonGroup', {
			'ButtonGroup--stacked ': linksStacked,
			'ButtonGroup--stackedSmallOnly ': linksStackedSmOnly,
		})}>
			{links.map((link, index) => {
				return (
					<CardLink key={index} link={link} {...props} />
				);
			})}
		</div>
	);
};
