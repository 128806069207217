import React, { useEffect, useState } from 'react';

import styles from '#/locations/deliveryCenterDetails.module.scss';
import { Address } from '~/locations/Components/Address';
import { Contact } from '~/locations/Components/Contact';
import { Hours } from '~/locations/Components/Hours';
import { useStoreContext } from '~/locations//Contexts/StorePages.context';

export const DeliveryCenterDetails = () => {
	const {
		model: {
			dcHoursHtml,
			dcEmergencyMessaging: {
				html,
			},
			dcSpecialMessaging,
			dcKey,
			deliveryCenter: {
				address,
				hours,
				title,
				contact: {
					email,
					phone,
				}
			}
		} = {},
	} = useStoreContext();

	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		setHasLoaded(true);
	}, []);

	return (
		<div id={`dc-${dcKey}`} className={`${styles.DeliveryCenterDetails}`} data-qa="delivery-center-details">
			<div>
				<h2 className="Heading Heading--3 tw-text-gray tw-mb-2 md:tw-mb-8">{title}</h2>
				<p className="Body Body--1 tw-mb-8">
					{hasLoaded && html &&
						<div className={styles.DeliveryCenterDetailsMessaging} dangerouslySetInnerHTML={{ __html: `${html}` }}></div>
					}
					Pick up items at your local delivery center or <a href="/customer-service/delivery">learn about delivery</a>.
				</p>
			</div>
			<div className="tw-flex tw-flex-col md:tw-space-x-5 md:tw-flex-row">
				<div className="md:tw-w-1/2 md:tw-mb-0 tw-mb-6">
					{hasLoaded &&
						<Hours hours={hours} hoursHtml={dcHoursHtml} specialMessaging={dcSpecialMessaging} />
					}
				</div>
				<div className="md:tw-w-1/2">
					<Address isStore={false} address={address} />
					<Contact email={email} phone={phone} toEmailName={title} isStore={false} />
				</div>
			</div>
		</div>
	);
};
