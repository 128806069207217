import classNames from 'classnames';
import React from 'react';

import { isEngage } from '~/global/global.constants';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

const ViewerBaseButton = (props) => {
	const {
		className, hidden, mouseDownHandler, style, isNext, onClick, parentKey = '',
	} = props;
	const classVariant = isNext ? 'next' : 'prev';
	const accessibleText = isNext ? 'Next' : 'Previous';
	const dataQaVariant = isNext ? 'next' : 'previous';

	return (
		<button
			key={`${parentKey}-${dataQaVariant}-nav-button`}
			className={
				classNames(`${className} slick-${classVariant} slick-nav-button slick-nav-button-${classVariant} tw-hidden md:tw-flex tw-hidden-for-print`, {
					'tw-sr-only': hidden,
					'focus:tw-not-sr-only': hidden,
				})
			}
			style={{ ...style }}
			data-qa={`media-viewer-${dataQaVariant}`}
			data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
			data-tr-link-event-name={isNext ? 'right navigation' : 'left navigation'}
			data-tr-link-event-track={!isEngage}
			disabled={className.includes('slick-disabled')}
			onClick={onClick}
			onMouseDown={() => {
				if (mouseDownHandler) {
					mouseDownHandler();
				}
			}}
			onTouchStart={() => {
				if (mouseDownHandler) {
					mouseDownHandler();
				}
			}}
		>
			<span className="nav-button-display"></span>
			<span className="tw-sr-only">{accessibleText}</span>
		</button>
	);
};

export const ViewerNextButton = (props) => {
	return <ViewerBaseButton key={`${props.parentKey}-next-parent-nav-button`} {...props} isNext={true} />;
};

export const ViewerPrevButton = (props) => {
	return <ViewerBaseButton key={`${props.parentKey}-prev-parent-nav-button`} {...props} isNext={false} />;
};
