export class Hours {
	days

	times
}

export const HoursModelFactory = ({
	create: (hoursJson = {}) => {
		const {
			days = '',
			times = '',
		} = hoursJson;

		const model = new Hours();

		Object.assign(model, {
			days,
			times,
		});

		return model;
	},
});
