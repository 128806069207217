/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, {
	forwardRef, useEffect, useRef, useState
} from 'react';

import type { WillChangeEvent } from '@egjs/react-flicking';
import type { ForwardedRef, KeyboardEvent } from 'react';

import { EVENTS } from '~/components/Slider/Slider.constants';
import { S7Image } from '~/components/Images/S7Image';
import { flickingMethod, handleKeyUpClickEvent } from '~/components/Slider/Slider.utils';
import { inRange } from '~/util/inRange';

import type { GallerySliderPanelImageProps } from '~/components/Gallery/Gallery.types';

import styles from '~/components/Gallery/GallerySliderPanelImage.module.scss';

export const GallerySliderPanelImage = forwardRef(({
	defaultIndex = 0,
	defaultSliderHeight,
	flickingRef,
	index,
	panelCount,
	showSliderPanelChildren,
	sliderHeight,
	sliderPanelComponentProps: {
		aspectRatio,
		children,
		imageProps,
	},
	useInlineStyle,
}: GallerySliderPanelImageProps, ref: ForwardedRef<HTMLDivElement>) => {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex);

	const isCurrent = index === currentIndex;

	const lastIndex = panelCount - 1;

	const isInRangeFirst = index === 0 && currentIndex === lastIndex;

	const isInRangeMiddle = inRange(index, currentIndex - 1, currentIndex + 2);

	const isInRangeLast = index === lastIndex && currentIndex === 0;

	const isInRange = isInRangeFirst || isInRangeMiddle || isInRangeLast;

	const defaultPanelWidth = Math.round(aspectRatio * defaultSliderHeight);

	const panelWidth = Math.round(aspectRatio * sliderHeight);

	const shouldLoadRef = useRef(isInRange);

	shouldLoadRef.current = shouldLoadRef.current || isInRange;

	async function handleClick() {
		await flickingMethod(async () => {
			await flickingRef.current?.moveTo(index);
		});
	}

	// manually add back keyboard support to match standard onClick handling
	function handleKeyUp(event: KeyboardEvent) {
		handleKeyUpClickEvent(event, handleClick);
	}

	function handleWillChange({ index: newIndex }: WillChangeEvent) {
		setCurrentIndex(newIndex);
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	return (
		<div
			className={
				classNames(styles.gallerySliderPanelImage, {
					[styles.gallerySliderPanelImageCurrent]: isCurrent,
					[styles.gallerySliderPanelImageFocusable]: !isCurrent,
				})
			}
			data-qa="gallery-slider-panel"
			onClick={!isCurrent ? handleClick : undefined}
			onKeyUp={!isCurrent ? handleKeyUp : undefined}
			ref={ref}
			role={!isCurrent ? 'button' : undefined}
			style={useInlineStyle ? { width: panelWidth } : undefined}
		>
			<S7Image
				{...imageProps}
				draggable={false}
				height={defaultSliderHeight}
				priority
				quality={!shouldLoadRef.current ? 5 : undefined}
				width={defaultPanelWidth}
			/>
			{
				showSliderPanelChildren && isCurrent && children
			}
		</div>
	);
});
