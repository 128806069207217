import { makeObservable, computed } from 'mobx';

import { AddressFactory } from '~/locations/Models/Address.model';
import { ContactFactory } from '~/locations/Models/Contact.model';
import { HoursModelFactory } from '~/locations/Models/Hours.model';

export class DeliveryCenter {
	data

	address

	contact

	hours

	constructor() {
		makeObservable(this, {
			key: computed,
			title: computed,
		});

		this.hours = [];
	}

	get key() {
		return this.data.key || '';
	}

	get title() {
		return this.data.title || '';
	}

	get openingHours() {
		const {
			schema: {
				openingHours = null,
			} = {},
		} = this.data;
		return openingHours;
	}
}

export const DeliveryCenterModelFactory = ({
	create: (data = {}) => {
		const {
			hours = [],
			address = {},
			contact = {},
		} = data;

		const model = new DeliveryCenter();
		model.data = data;

		model.hours = hours.map((hourData) => {
			return HoursModelFactory.create(hourData);
		});

		model.address = AddressFactory.create(address);

		model.contact = ContactFactory.create(contact);

		return model;
	},
});
