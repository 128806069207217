import { observable, makeObservable, computed } from 'mobx';
import parse, { domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';

import { TextLink } from '~/components/Buttons/Components/TextLink';

import { storeData } from '~/locations/Data/storeData';
import { storeFaves, faveProducts } from '~/locations/Components/favorites/favorites-data';
import { AddressFactory } from '~/locations/Models/Address.model';
import { ContactFactory } from '~/locations/Models/Contact.model';
import { HoursModelFactory } from '~/locations/Models/Hours.model';
import { FavoritesModelFactory } from '~/locations/Models/Favorites.model';

/* eslint-disable */
export class Store {
	_links

	storeNumber

	aboutUsHtml

	deliveryCenter

	storeSpecialMessaging

	storeEmergencyMessaging

	dcSpecialMessaging

	dcEmergencyMessaging

	favoriteProducts

	mainImageAltText

	metaDescription

	categoryId

	events

	jsonData

	hours

	favorites

	mediaSet

	constructor() {
		makeObservable(this, {
			events: observable,
			storeName: computed,
			aboutUsComponents: computed,
		});

		this.hours = [];
	}

	get aboutUsComponents() {
		if (typeof window === 'undefined') {
			return null;
		}
		const cleanHtml = DOMPurify.sanitize(this.aboutUsHtml);
		return parse(cleanHtml, {
			replace: (domNode) => {
				if (domNode.name === 'a') {
					const { href, ...rest } = domNode.attribs;
					const children = domNode.children ? domToReact(domNode.children) : null;
					return (
						<TextLink href={href} linkExternal className="tw-text-xl tw-font-light tw-inline tw-break-word" {...rest}>
							{children}
						</TextLink>
					);
				}
			},
		});
	}

	get breadcrumbID() {
		const {
			location: {
				breadcrumbID = '',
			} = {},
		} = this.jsonData;
		return breadcrumbID;
	}

	get dcKey() {
		const {
			location: {
				deliveryCenter = '',
			} = {},
		} = this.jsonData;
		return deliveryCenter;
	}

	get titleOverride() {
		const {
			location: {
				titleOverride = '',
			} = {},
		} = this.jsonData;
		return titleOverride;
	}

	get hashTag() {
		const {
			location: {
				hashTag = '',
			} = {},
		} = this.jsonData;
		return hashTag;
	}

	get imageName() {
		const {
			location: {
				imageName = '',
			} = {},
		} = this.jsonData;
		return imageName;
	}

	get isNewStore() {
		const {
			location: {
				isNewStore = false,
			} = {},
		} = this.jsonData;
		return isNewStore;
	}

	get jrni() {
		const {
			location: {
				jrni = '',
			} = {},
		} = this.jsonData;
		return jrni;
	}

	get key() {
		const {
			location: {
				key = '',
			} = {},
		} = this.jsonData;
		return key;
	}

	get noGallery() {
		const {
			location: {
				noGallery = false,
			} = {},
		} = this.jsonData;
		return noGallery;
	}

	get openingHours() {
		const {
			location: {
				schema: {
					openingHours = null,
				} = {},
			} = {},
		} = this.jsonData;
		return openingHours;
	}

	get storeName() {
		const {
			location: {
				title: {
					text = '',
				} = {},
			} = {},
		} = this.jsonData;
		return text;
	}

	get storeNumber() {
		const {
			location: {
				storeNumber = '',
			} = {},
		} = this.jsonData;
		return storeNumber;
	}

	get storeSuffix() {
		const {
			location: {
				title: {
					suffix = '',
				} = {},
			} = {},
		} = this.jsonData;
		return suffix;
	}

	get virtualTourLink() {
		const {
			location: {
				virtualTourLink: {
					cdata = '',
				} = {},
			} = {},
		} = this.jsonData;
		return cdata;
	}
}

export const StoreModelFactory = ({
	create: (pageModel = {}) => {
		const model = new Store();
		const {
			query: {
				store = '',
			} = {},
		} = pageModel;

		Object.assign(model, {
			...pageModel,
		});

		if (storeData[store]) {
			const {
				location: locationData,
			} = storeData[store];

			model.jsonData = storeData[store];

			if (locationData) {
				model.hours = model.isNewStore ? null : locationData.hours.range.map((hourData) => {
					return HoursModelFactory.create(hourData);
				});

				model.contact = ContactFactory.create(locationData.contact);

				model.address = AddressFactory.create(locationData.address);
			}
		}

		if (storeFaves[store]) {
			model.favorites = storeFaves[store].map((favorite) => {
				return FavoritesModelFactory.create(faveProducts[favorite]);
			});
		}

		return model;
	},
});
