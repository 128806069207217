import React from 'react';

import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { MediaSetWithSliderNav } from '~/media-set/Components/MediaSetWithSliderNav';
import { MediaSetMinimal } from '~/media-set/Components/MediaSetMinimal';
import styles from '#/locations/imageViewer.module.scss';

export const ImageViewer = () => {
	const {
		model: {
			mediaSet,
		} = {},
		mediaSetStore,
		startingImageIndex,
		showFullScreenButton = false,
		validatedStoreQuery = 'not_found',
	} = useStoreContext();

	if (!mediaSet) {
		return false;
	}

	return (
		<div
			aria-label="Store media carousel"
			data-tr-link-event-comp-type="showroom media viewer"
			role="region"
		>
			<div className={styles['image-viewer']}>
				<MediaSetWithSliderNav
					initialSlide={startingImageIndex}
					mediaSetModel={mediaSet}
					infinite={true}
					mainHeight={732}
					mainWidth={993}
					showArrowsForMedium={true}
					navSlidesToShow={5}
					mediaSetStore={mediaSetStore}
					showFullScreenButton={showFullScreenButton}
					trLinkEventCompType="showroom media viewer"
					trLinkEventUseCompNameH1={true}
				/>
			</div>
			<div className={styles['minimal-image-viewer']}>
				<MediaSetMinimal
					initialSlide={startingImageIndex}
					mainHeight={350}
					mediaSetModel={mediaSet}
					infinite={false}
					mediaSetStore={mediaSetStore}
					trLinkEventCompType="showroom media viewer"
					trLinkEventUseCompNameH1={true}
				/>

				<div className={styles['see-the-store-button-container']}>
					<a
						href={`/stores/${validatedStoreQuery}/gallery`}
						className={`HollowButtonSupport ${styles['see-the-store-button']}`}
						data-qa="see-the-store-button"
					>
						See the Store
					</a>
				</div>
			</div>
		</div>
	);
};
