const faveProducts = {
	ardenRugs: {
		link: '/catalog/rugs/solid-rugs/arden-shag-rugs/425087?articleNumber=425087',
		name: 'Arden rugs',
		s7Image: '425087?',
	},
	ariaDiningTables: {
		link: 'https://go.roomandboard.com/E68DB2',
		name: 'Aria dining tables',
		s7Image: '?src=ir{roomandboardrender/aria_48r_29h_3l?obj=top/wood&show&obj=top/wood/top&src=rpt_Wrd_top&obj=top/wood/front_edge&src=rpt_Wrd_front_edge&obj=base/SS&show}',
	},
	ariaDiningTablesRound: {
		link: '/catalog/dining-and-kitchen/tables/aria-round-tables/752470?CHAR_752470_ARIA_COLL_TOP=WHMDF&CHAR_752470_ARIA_COLL_BASE=SS',
		name: 'Aria dining tables',
		s7Image: '?src=ir{roomandboardrender/aria_48r_29h_3l?obj=top/mdf&show&obj=top/mdf/top&src=rpt_PWrd_top&obj=top/mdf/front_edge&src=rpt_PWrd_front_edge&obj=color&show&src=rpt_SS}',
	},
	ariaDiningTablesSpalted: {
		link: '/catalog/dining-and-kitchen/tables/aria-square-tables/525737?articleNumber=525737&CHAR_525737_ARIA_COLL_TOP=SGRBR&CHAR_525737_ARIA_COLL_BASE=GRPH',
		name: 'Aria dining tables',
		s7Image: '?src=ir{roomandboardrender/aria_40w_40d_29h_3l?obj=top/wood&show&obj=top/wood/top&src=rpt_SB_top&obj=top/wood/front_edge&src=rpt_SB_front_edge&obj=top/wood/left_edge&src=rpt_SB_left_edge&obj=base/GP&show}',
	},
	berkeleyDressers: {
		link: '/catalog/bedroom/dressers/berkeley-dressers/186050?articleNumber=186050&CHAR_186050_BERKELEY_COLL_WOOD=WA&CHAR_186050_BERKELEY_COLL_HDWR=BRNK',
		name: 'Berkeley dressers',
		s7Image: '?layer=0&src=186050_wood_W&layer=1&src=186050_pull_brnk&layer=comp',
	},
	berkeleyDressersCharcoal: {
		link: '/catalog/bedroom/dressers/berkeley-dressers/186050?articleNumber=186050&CHAR_186050_BERKELEY_COLL_WOOD=CHCHAR&CHAR_186050_BERKELEY_COLL_HDWR=BRNK',
		name: 'Berkeley dressers',
		s7Image: '?layer=0&src=186050_wood_CHAR&layer=1&src=186050_pull_brnk&layer=comp',
	},
	berkeleyDressersWhiteOak: {
		link: '/catalog/bedroom/dressers/berkeley-dressers/937388?articleNumber=937388&CHAR_937388_BERKELEY_COLL_WOOD=WHOAK&CHAR_937388_BERKELEY_COLL_HDWR=BRNK',
		name: 'Berkeley dressers',
		s7Image: '?layer=0&src=937388_wood_WO&layer=1&src=937388_pull_brnk&layer=comp',
	},
	cadeSofas: {
		link: '/catalog/living/sofas-and-loveseats/cade-sofas/378243?articleNumber=378243&CHAR_378243_BANKS_COLORWAYS=C15&CHAR_378243_UPHOL_FINISH=SS',
		name: 'Cade sofas',
		s7Image: '?src=ir{roomandboardrender/cade_sf2cush_90?obj=main&sharp=1&src=rpt_bankscharcoal&sharp=0&obj=material&src=drape_material_ss}',
	},
	coraDiningChairs: {
		link: '/catalog/dining-and-kitchen/chairs/cora-chair/854140?articleNumber=854140',
		name: 'Cora dining chairs',
		s7Image: '?src=ir{roomandboardrender/cora_chrdiningside_10?obj=main&sharp=1&src=rpt_holtzcharcoal&rs=R3U2&obj=material&src=drape_material_cc}',
	},
	emmetSofas: {
		link: '/catalog/outdoor/sofas/emmet-sofas',
		name: 'Emmet sofas',
		s7Image: '?src=ir{roomandboardrender/emmet_design5_50?obj=color&show&src=rpt_GY}',
	},
	emmetOutdoorLoungeChairs: {
		link: 'https://go.roomandboard.com/80E5E5',
		name: 'Emmet outdoor lounge chairs',
		s7Image: '?src=ir{roomandboardrender/emmet_design7_20?obj=color&show&src=rpt_WH}',
	},
	herizRugs: {
		link: '/catalog/rugs/all-rugs/heriz-rugs/625136?articleNumber=625136',
		name: 'Heriz rugs',
		s7Image: '044904?',
	},
	hudsonDressers: {
		link: 'https://go.roomandboard.com/3CEFC2',
		name: 'Hudson dressers',
		s7Image: '?layer=0&src=394066_021308_wood_SAND&layer=1&src=394066_021308_base_SAND&layer=2&src=394066_021308_knob_NS&layer=comp',
	},
	hudsonDressersWhiteOak: {
		link: 'https://go.roomandboard.com/E70D81',
		name: 'Hudson dressers',
		s7Image: '?layer=0&src=052668_088848_wood_WO&layer=1&src=052668_088848_base_WO&layer=2&src=052668_088848_knob_NS&layer=comp',
	},
	infinityMirrors: {
		link: '/catalog/home-decor/mirrors/infinity-round-wall-mirrors/462138?CHAR_462138_INFINITY_MIR_COLL_COLOR=PTN',
		name: 'Infinity mirrors',
		s7Image: '?src=ir{roomandboardrender/infinity_36r_nullh?obj=color&show&src=rpt_COGN}',
	},
	infinityMirrorsLeaning: {
		link: 'https://go.roomandboard.com/38D59C',
		name: 'Infinity mirrors',
		s7Image: '585695?',
	},
	julianDining: {
		link: '/catalog/dining-and-kitchen/tables/aria-round-tables/827856?CHAR_827856_JULIAN_COLL_TOP=WHOAK&CHAR_827856_JULIAN_COLL_BASE=ONXGL',
		name: 'Aria oval tables',
		s7Image: '?src=ir{roomandboardrender/aria_78w_48d_29h?obj=top/wood&show&obj=top/wood/top&src=rpt_WOrd_top&obj=top/wood/front_edge&src=rpt_WOrd_front_edge&obj=base/GP&show}',
	},
	kalindiRugs: {
		link: '/catalog/rugs/patterned-rugs/kalindi-rugs/259090?articleNumber=259090',
		name: 'Kalindi rugs',
		s7Image: '259090?',
	},
	kenwoodDressersCeramic: {
		link: '/catalog/bedroom/dressers/kenwood-dressers-with-ceramic-tops/516435?articleNumber=516435&CHAR_516435_KENWOOD_COLL_TOP=MBBLKCER&CHAR_516435_KENWOOD_COLL_BASE=GRPH&CHAR_516435_KENWOOD_COLL_WOOD=ASHSHEL&CHAR_516435_KENWOOD_COLL_HDWR=GRPH',
		name: 'Kenwood dressers with ceramic tops',
		s7Image: '?layer=0&src=516435_wood_SHELL&layer=1&src=516435_base_GP&layer=2&src=516435_pull_GRPH&layer=3&src=516435_top_MBBLKCER&layer=comp',
	},
	kenwoodDressersSand: {
		link: '/catalog/bedroom/dressers/kenwood-dressers/899535?articleNumber=899535&CHAR_899535_KENWOOD_COLL_WOOD=ASHSA&CHAR_899535_KENWOOD_COLL_BASE=SS&CHAR_899535_KENWOOD_COLL_HDWR=SS',
		name: 'Kenwood dressers',
		s7Image: '?layer=0&src=899535_wood_SAND&layer=1&src=899535_base_SS&layer=2&src=899535_pull_SS&layer=comp',
	},
	kendwoodNightstands: {
		link: 'https://go.roomandboard.com/A9A223',
		name: 'Kenwood nightstands with ceramic tops',
		s7Image: '?layer=0&src=477735_wood_SAND&layer=1&src=477735_base_GP&layer=2&src=477735_pull_GRPH&layer=3&src=477735_top_MBBLKCER&layer=comp',
	},
	kendwoodNightstandsWalnut: {
		link: '/catalog/bedroom/nightstands/kenwood-nightstands-with-ceramic-tops/477735?articleNumber=477735&CHAR_477735_KENWOOD_COLL_BASE=SS&CHAR_477735_KENWOOD_COLL_TOP=MBWHTCER&CHAR_477735_KENWOOD_COLL_WOOD=WA&CHAR_477735_KENWOOD_COLL_HDWR=SS',
		name: 'Kenwood nightstands with ceramic tops',
		s7Image: '?layer=0&src=477735_wood_W&layer=1&src=477735_base_SS&layer=2&src=477735_pull_SS&layer=3&src=477735_top_MBWHTCER&layer=comp',
	},
	kayseriRugs: {
		link: '/catalog/rugs/patterned-rugs/kayseri-rugs/435785?articleNumber=435785',
		name: 'Kayseri rugs',
		s7Image: '058802?',
	},
	metroSofas: {
		link: 'https://go.roomandboard.com/68E233',
		name: 'Metro sofas',
		s7Image: '?src=ir{roomandboardrender/metro_sf2cush_80?obj=main&sharp=1&src=rpt_banksfir}',
	},
	metroSofasBanks: {
		link: '/catalog/living/sofas-and-loveseats/metro-sofas/779258?articleNumber=779258&CHAR_779258_GINO_COLORWAYS=C15',
		name: 'Metro sofas',
		s7Image: '?src=ir{roomandboardrender/metro_sf2cush_80?obj=main&sharp=1&src=rpt_bankscharcoal}',
	},
	metroSofasTwoCush: {
		link: '/catalog/living/sofas-and-loveseats/metro-sofas/824626?articleNumber=824626',
		name: 'Metro sofas',
		s7Image: '?src=ir{roomandboardrender/metro_sf2cush_80?obj=main&sharp=1&src=rpt_destinlinen&repeat=6&rs=R4U3&sharp=0&obj=material&src=drape_material_cc}',
	},
	miraSofas: {
		link: '/catalog/living/sofas-and-loveseats/mira-sofas/793827?articleNumber=793827&CHAR_793827_UPHOL_FINISH=CC&CHAR_793827_DESTIN_COLORWAYS=P10',
		name: 'Mira sofas',
		s7Image: '?src=ir{roomandboardrender/mira_sf2cush_std_90?obj=main&sharp=1&src=rpt_destinputty&repeat=6&rs=R4U3&sharp=0&obj=material&src=drape_material_CHAR}',
	},
	morrisonSofas: {
		link: '/catalog/living/sofas-and-loveseats/morrison-sofas/754974?articleNumber=754974',
		name: 'Morrison sofas',
		s7Image: '?src=ir{roomandboardrender/morrison_sf_90?obj=main&sharp=1&src=rpt_tepiccharcoal&rs=R3&repeat=7&obj=material&src=drape_material_cc}',
	},
	morrisonSofasCharcoal: {
		link: '/catalog/living/sofas-and-loveseats/morrison-sofas/754974?articleNumber=754974',
		name: 'Morrison sofas',
		s7Image: '?src=ir{roomandboardrender/morrison_sf_90?obj=main&sharp=1&src=rpt_tepiccharcoal&rs=R3&repeat=7&obj=material&src=drape_material_cc}',
	},
	parsonsDining: {
		link: '/catalog/dining-and-kitchen/tables/parsons-tables/848496?articleNumber=848496&CHAR_848496_PARSONS_COLL_TOP=MBWHTCER&CHAR_848496_PARSONS_COLL_BASE=NS',
		name: 'Parsons dining tables',
		s7Image: '?src=ir{roomandboardrender/parsons_60w_36d_29h_2l?obj=top/stone&show&obj=top/stone/top&src=rpt_MBWHTCER_top&obj=top/stone/front_edge&src=rpt_MBWHTCER_front_edge&obj=top/stone/left_edge&src=rpt_MBWHTCER_left_edge&obj=base/naturalsteel&show}',
	},
	parsonsDiningWalnut: {
		link: '/catalog/dining-and-kitchen/tables/parsons-tables/848496?articleNumber=848496&CHAR_848496_PARSONS_COLL_TOP=WA&CHAR_848496_PARSONS_COLL_BASE=NS',
		name: 'Parsons dining tables',
		s7Image: '?src=ir{roomandboardrender/parsons_60w_36d_29h_2l?obj=top/wood&show&obj=top/wood/top&src=rpt_W_top&obj=top/wood/front_edge&src=rpt_W_front_edge&obj=top/wood/left_edge&src=rpt_W_left_edge&obj=base/naturalsteel&show}',
	},
	prattDining: {
		link: '/catalog/dining-and-kitchen/tables/parsons-tables/766982?CHAR_766982_PARSONS_COLL_TOP=MBWHTCER&CHAR_766982_PARSONS_COLL_BASE=NS',
		name: 'Parsons dining tables',
		s7Image: '?src=ir{roomandboardrender/p3_72w_36d_29h_2l?obj=top/stone&show&obj=top/stone/top&src=rpt_MBWHTCER_top&obj=top/stone/front_edge&src=rpt_MBWHTCER_front_edge&obj=top/stone/left_edge&src=rpt_MBWHTCER_left_edge&obj=color&show&src=rpt_NS}',
	},
	yorkSofas: {
		link: '/catalog/living/sofas-and-loveseats/york-sofas/451532?articleNumber=451532',
		name: 'York sofas',
		s7Image: '?src=ir{roomandboardrender/york_sf_90?obj=main&sharp=1&src=rpt_sumnerlinen&rs=Q5R4U3}',
	},
};

const storeFaves = {
	'atlanta': ['morrisonSofas', 'prattDining', 'berkeleyDressers', 'herizRugs'],
	'austin': ['metroSofas', 'ariaDiningTables', 'kendwoodNightstands', 'infinityMirrorsLeaning'],
	'bethesda': ['yorkSofas', 'ariaDiningTablesRound', 'berkeleyDressersWhiteOak', 'kayseriRugs'],
	'boston': ['metroSofas', 'parsonsDining', 'hudsonDressers', 'infinityMirrors'],
	'chelsea': ['metroSofas', 'ariaDiningTables', 'kendwoodNightstands', 'infinityMirrorsLeaning'],
	'chicago': ['metroSofas', 'ariaDiningTables', 'kendwoodNightstands', 'infinityMirrorsLeaning'],
	'costa-mesa': ['metroSofasTwoCush', 'coraDiningChairs', 'kenwoodDressersSand', 'emmetOutdoorLoungeChairs'],
	'dallas': ['metroSofas', 'ariaDiningTables', 'kendwoodNightstands', 'infinityMirrorsLeaning'],
	'denver': ['yorkSofas', 'ariaDiningTablesRound', 'berkeleyDressersWhiteOak', 'kayseriRugs'],
	'east-hampton': ['cadeSofas', 'julianDining', 'kendwoodNightstandsWalnut', 'emmetSofas'],
	'edina': ['metroSofasTwoCush', 'parsonsDiningWalnut', 'hudsonDressersWhiteOak', 'ardenRugs'],
	'los-angeles': ['metroSofas', 'parsonsDining', 'hudsonDressers', 'infinityMirrors'],
	'oak-brook': ['morrisonSofasCharcoal', 'parsonsDining', 'kenwoodDressersCeramic', 'kalindiRugs'],
	'palm-springs': ['cadeSofas', 'julianDining', 'kendwoodNightstandsWalnut', 'emmetSofas'],
	'paramus': ['metroSofas', 'ariaDiningTables', 'kendwoodNightstands', 'infinityMirrorsLeaning'],
	'pasadena': ['cadeSofas', 'julianDining', 'kendwoodNightstandsWalnut', 'emmetSofas'],
	'portland': ['metroSofasBanks', 'ariaDiningTablesSpalted', 'berkeleyDressersCharcoal', 'infinityMirrorsLeaning'],
	'san-diego': ['metroSofasTwoCush', 'coraDiningChairs', 'kenwoodDressersSand', 'emmetOutdoorLoungeChairs'],
	'san-francisco': ['metroSofasTwoCush', 'parsonsDiningWalnut', 'hudsonDressersWhiteOak', 'ardenRugs'],
	'seattle': ['metroSofasBanks', 'ariaDiningTablesSpalted', 'berkeleyDressersCharcoal', 'infinityMirrorsLeaning'],
	'skokie': ['metroSofasBanks', 'ariaDiningTablesSpalted', 'berkeleyDressersCharcoal', 'infinityMirrorsLeaning'],
	'washington-dc': ['yorkSofas', 'ariaDiningTablesRound', 'berkeleyDressersWhiteOak', 'kayseriRugs'],
};

export {
	faveProducts,
	storeFaves,
};
