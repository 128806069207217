import React, { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from '~/util/curalate/Components/curalate.module.scss';
import { CuralateVariant } from '~/util/curalate/Components/curalate-container/CuralateContainer.constants';

interface IProps {
	className?: string;
	curalateItems?: ReactNode[];
	trLinkEventCompName: string;
	variant?: CuralateVariant;
}

export const CuralateContainer = (props: PropsWithChildren<IProps>) => {
	const { className = '', curalateItems = [], variant = CuralateVariant.Standard } = props;

	return (
		<div
			aria-label={'Instagram carousel'}
			className={classNames(`${styles['curalate-container']} screen-only`, className, {
				[styles['curalate-container-no-items']]: !curalateItems.length,
				[styles.curalateContainerCareers]: variant === CuralateVariant.Careers
			})}
			data-tr-link-event-comp-type={'bazaarvoice social commerce'}
			data-tr-link-event-comp-name={props.trLinkEventCompName}
			id={'instagram-carousel'}
			role={'region'}
		>
			{props.children}
		</div>
	);
};
