import EventRSVPFactory from '~/locations/Models/EventRSVP.model';

export class Event {
	id

	name

	date

	title

	description

	time

	contactInfo

	validFromTs

	validToTs

	locationNumber

	calendarDateDisplay

	rsvp

	eventDateDisplay

	get hasRsvp() {
		return Boolean(this.rsvp);
	}
}

export const EventFactory = ({
	create: (eventJson = {}) => {
		const {
			id,
			name,
			eventDate: date,
			title,
			description,
			timeInfo: time,
			contactInfo,
			validFrom: validFromTs,
			validTo: validToTs,
			locationNumber,
			calendarDateDisplay,
			eventDateDisplay,
		} = eventJson;

		const model = new Event();

		Object.assign(model, {
			id,
			name,
			date,
			title,
			description,
			time,
			contactInfo,
			validFromTs,
			validToTs,
			locationNumber,
			calendarDateDisplay,
			eventDateDisplay,
		});

		return model;
	},
});
