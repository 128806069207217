import React from 'react';
import classNames from 'classnames';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { Link } from '~/components/Buttons/Components/Link';

export const CardLink = (props) => {
	const {
		link: {
			isTextLink = false,
			linkClass,
			linkCopy,
			linkDataQa,
			linkURL,
			linkExternal = false,
		},
		trLinkEventCompName,
		trLinkEventCompType,
	} = props;

	const linkClasses = classNames(
		linkClass || 'Body Body--2',
	);

	const linkExtUrl = linkURL.startsWith('http') && !linkURL.includes('go.roomandboard.com');

	const linkAttributes = {
		'className': linkClasses,
		'href': linkURL,
		'target': linkExtUrl || linkURL.endsWith('.pdf') ? '_blank' : '',
	};

	if (trLinkEventCompName) {
		linkAttributes['data-tr-link-event-comp-name'] = trLinkEventCompName;
	}

	if (trLinkEventCompType) {
		linkAttributes['data-tr-link-event-comp-type'] = trLinkEventCompType;
	}
	if (linkDataQa) {
		linkAttributes.dataQa = linkDataQa;
	}
	if (linkExternal) {
		linkAttributes.rel = 'no opener no referrer';
		linkAttributes.target = '_blank';
		linkAttributes.linkExternal = true;
	}

	if (isTextLink) {
		return (
			<TextLink {...linkAttributes}>
				<span className={linkClasses}>
					{linkCopy}
				</span>
			</TextLink>
		);
	}

	return (
		<Link {...linkAttributes}>{linkCopy}</Link>
	);
};
