// Slider using React Slick where you can pass in any content
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import defaultStyles from './content-slick-slider.module.scss';
import sharedStyles from './content-slick-slider-shared.module.scss';

import { debounce } from '~/util/debounce';
import { isOnServer } from '~/global/global.constants';
import { CSSStyles } from '~/util/typescript/sass-types';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { noop } from '~/util/noop';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { updateSlidesAttributes } from '~/media-set/Utils/Media.utils';

export type SliderProps = {
	afterIntersectionObserver?: Function,
	content: JSX.Element[],
	isContentSilderHidden?: boolean,
	screenReaderText?: string,
	settings: Settings,
	stylesOverride?: CSSStyles,
	trLinkEventCompName?: string,
	trLinkEventCompType?: string,
	trLinkEventUseCompNameH1?: boolean,
}

export const ContentSlickSlider = observer((props: SliderProps) => {
	const {
		settings: {
			afterChange,
			beforeChange,
			className,
			infinite = true,
			initialSlide = 0,
			nextArrow,
			onInit,
			prevArrow,
			responsive = [],
			slidesToShow = 1,
			slidesToScroll = 1,
			speed = 300,
			touchMove = true,
			variableWidth = true,
		},
		afterIntersectionObserver = noop,
		content,
		isContentSilderHidden = false,
		stylesOverride = null,
		trLinkEventCompName,
		trLinkEventCompType,
		trLinkEventUseCompNameH1 = null,
	} = props;

	const {
		linkEventStore,
	} = useGlobalContext();

	const styles = stylesOverride || defaultStyles;

	const containerRef = useRef<HTMLDivElement>(null);

	// if the slide is "visible" add class slick-active
	function activeClass(element: Element, isVisible: boolean) {
		if (isVisible) {
			element.classList.add('slick-active');
		} else {
			element.classList.remove('slick-active');
		}
	}

	const options = {
		root: containerRef.current,
		rootMargin: '0px',
		threshold: 0.5,
	};

	let sliderObserver: IntersectionObserver | null;
	let observedContent: NodeList | null = containerRef.current ? containerRef.current.querySelectorAll('.slick-slide') : null;

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		entries.forEach((element) => {
			activeClass(element.target, element.isIntersecting);
		});
		updateSlidesAttributes(containerRef);
		// optional callback
		afterIntersectionObserver();
	};

	const handleResize = debounce(() => {
		if (observedContent !== null) {
			observedContent.forEach((slideContent: Node) => {
				sliderObserver?.observe(slideContent as Element);
			});
		}
	}, 100);

	useEffect(() => { // intersection observer for slider content
		sliderObserver = new IntersectionObserver(handleIntersection, options);
		if (!isOnServer && containerRef.current) {
			observedContent = containerRef.current.querySelectorAll('.slick-slide');
			observedContent.forEach((slideContent: Node) => {
				sliderObserver?.observe(slideContent as Element);
			});
		}
		return () => {
			if (containerRef.current) {
				sliderObserver?.unobserve(containerRef.current);
			}
		};
	}, []);

	useEffect(() => { // event listener for the window resizing
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const sliderSettings = {
		afterChange,
		beforeChange,
		arrows: true,
		className,
		focusOnSelect: false,
		infinite,
		initialSlide,
		nextArrow,
		onInit,
		onSwipe: (direction: string) => {
			// if the user swipes left it is the equivelent of a right arrow navigation on desktop
			// e.g. user is on index 0, swipe left index = 1 which is the same as hitting the right arrow on desktop
			const navigationDirection = direction === 'left' ? 'right' : 'left';

			if (linkEventStore) {
				const linkEventTrackingData = {
					trLinkEventName: `${navigationDirection} navigation`,
					trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
					trLinkEventCompName,
					trLinkEventCompType,
					trLinkEventUseCompNameH1,
				};
				linkEventStore.trackLinkEvent(linkEventTrackingData);
			}
		},
		prevArrow,
		responsive,
		slidesToShow,
		slidesToScroll,
		speed,
		touchMove,
		variableWidth,
	};

	return (
		<div
			className={
				classNames(styles['content-slider'], {
					[styles['content-slider-hide']]: isContentSilderHidden,
				})
			}
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-use-comp-name-h1={trLinkEventUseCompNameH1} // if true this will override the comp name set above
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-qa="content-slider"
			ref={containerRef}
		>
			<Slider
				{...sliderSettings}
				className={`${sliderSettings.className} ${sharedStyles['content-slick-slider-shared']}`}
			>
				{content.map((sliderContent: ReactElement) => sliderContent)}
			</Slider>
		</div>
	);
});
