import React from 'react';

export const EventRSVP = (props = {}) => {
	const {
		event: {
			hasRsvp = false,
			rsvp: {
				friendlyUrl,
			} = {},
		},
		store,
	} = props;

	if (!hasRsvp) {
		return null;
	}

	return (
		<a
			className="ButtonAnchor Body Body--2"
			data-qa="event-rsvp"
			href={`/stores/${store}/${friendlyUrl}`}
		>
			RSVP to this event
		</a>
	);
};
